import styled from "styled-components"

export const ProductImageArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  /* border: 1px solid red; */

  .button-area {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 50px 0;
    .shop-now-btn {
      background: var(--darkBlue);
      color: white;
      padding: 5px 25px;
      border-radius: 12px;
      text-decoration: none;
      text-transform: uppercase;
    }

    .where-btn {
      color: var(--darkBlue);
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }
`

export const Zoom = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1111;
  width: 100%;
  height: 100%;
  top: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.9);
  button {
    &:hover {
      cursor: pointer;
    }
  }
  div {
    width: 60%;
    display: flex;
    justify-content: flex-end;
  }
  .moreImg {
    display: flex;
    width: 6%;
  }
  img {
    margin: 0;
    // width: 40% !important;
    height: 70%;
  }
  .close {
    color: var(--darkBlue);
    background-color: white;
    border: 1px solid var(--darkBlue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const FancyButton = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0.5px solid var(--darkBlue);
  transition: 0.1s transform;
  background-color: ${(props) =>
    props.element === props.imageFocus
      ? "var(--darkBlue)"
      : "var(--darkBlueTransparency)"};
`

export const ButtonArea = styled.section`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;

  &:hover {
    ${FancyButton} {
      transform: scale(1.5);
      @media (max-width: 769) {
        transform: "";
      }
    }
  }
`

export const DetailPage = styled.div`
  @media (max-width: 1024px) {
    .zoom {
      display: none;
    }
  }
`

export const BlogWrapper = styled.div`
  .title {
    margin: 0;
    text-align: center;
    color: var(--darkBlue);
  }

  // background-color: var(--pink);
  .link {
    text-decoration: none;
    color: var(--darkBlue);
    margin: 20px;

    &:hover {
      border-bottom: 1px solid var(--darkBlue);
      @media (max-width: 769px) {
        border-bottom: none;
      }
    }
  }
  & div {
    background: var(--white);
    margin: 0 auto;
    width: 100%;
  }
`

export const Blog = styled.div`
  @media (max-width: 1024px) {
    padding: 1rem;
  }
  background-color: var(--white);
  padding: 3rem 3rem;
  font-size: 16px;

  @media (min-width: 678px) {
    figure:first-child {
      width: 100%;

      float: ${(props) => (props.horizontal ? "" : "left")};
      width: ${(props) => (props.horizontal ? "" : "50%")};
      margin-right: ${(props) => (props.horizontal ? "" : "20px")};
    }
  }

  figure {
    text-align: center;
  }

  h1,
  h2,
  p,
  i,
  a {
    color: rgba(0, 0, 0, 0.84);
    text-rendering: optimizeLegibility;
  }

  h1 {
    text-align: left;
    margin-bottom: 8px;
  }

  h2 {
    font-weight: 700;
    padding: 0;
    margin: 56px 0 -13px -1.883px;
    text-align: left;
    line-height: 34.5px;
    letter-spacing: -0.45px;
  }

  p,
  i,
  a {
    margin-top: 21px;

    letter-spacing: -0.03px;
    line-height: 1.58;
  }

  a {
    text-decoration: underline;
  }

  blockquote {
    font-style: italic;
    letter-spacing: -0.36px;
    line-height: 44.4px;
    overflow-wrap: break-word;
    margin: 55px 0 33px 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.68);
    padding: 0 0 0 50px;
  }

  code {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    padding: 3px 5px;
  }
`
