import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layout"
import SEO from "../seo"
import { Link } from "gatsby"
import { BlogWrapper, Blog } from "../../styles/TemplatesFolderStyles"
import BodyWrapper from "../../templates/BodyWrapper"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export const query = graphql`
  query SINGLE_BLOG($slug: String!) {
    mediumFeed(slug: { eq: $slug }) {
      title
      content
      link
      thumbnail
    }
  }
`

const BlogPage = ({ data }) => {
  const [horizontal, sethorizontal] = useState(false)

  const handleSize = (image) => {
    if (image.target.offsetWidth > image.target.offsetHeight) {
      sethorizontal(true)
    }
  }
  const mediumPost = data.mediumFeed

  return (
    <Layout>
      <SEO title={mediumPost.title} />
      <BodyWrapper bg={"white"}>
        <BlogWrapper>
          <img
            style={{ visibility: "hidden" }}
            onLoad={(e) => handleSize(e)}
            src={mediumPost.thumbnail}
            alt={mediumPost.title}
            width="0.1%"
          />
          <div>
            <div style={{ paddingTop: "20px" }}>
              <Link class="link" to="/blog">
                All Blogs
              </Link>
              <OutboundLink
                class="link"
                rel="noopener noreferrer"
                target="_blank"
                href={mediumPost.link}
              >
                See post on medium
              </OutboundLink>
              <h1 className="title">{mediumPost.title}</h1>
            </div>
            <Blog
              horizontal={horizontal}
              dangerouslySetInnerHTML={{ __html: mediumPost.content }}
            />
          </div>
        </BlogWrapper>
      </BodyWrapper>
    </Layout>
  )
}

export default BlogPage
